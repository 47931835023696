export const TEMPLATE_LISTING_PAGE = "/"
export const SETTINGS_PAGE = "/settings"
export const TEMPLATE_PAGE = "/templates/:templateId";

export const INTENTWISE_ACCESS_PAGE = "/access/intentwise"

export const AMAZON_SELLER_CENTRAL_ACCESS_PAGE = "/access/amazon-seller-central"
export const AMAZON_SELLER_CENTRAL_REDIRECT_PAGE = "/redirect/amazon-seller-central/"

export const LOGIN_PAGE = "/login"
export const REGISTRATION_PAGE = "/registration";
export const PASSWORD_RESET_PAGE = "/forget-password/reset/:uid/:token"
export const ACCESS_DENIED_PAGE = "/access/denied"

export const get_template_page_url = (templateId) => {
  return TEMPLATE_PAGE.replace(":templateId", templateId)
}
