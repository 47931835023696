import React, { useEffect, useState } from "react";
import LayoutContainer from "../../components/layouts/LayoutContainer";
import classes from "./styles.module.scss";
import { setPageTitle } from "../../utils";
import ConnectFormHeader from "./ConnectFormHeader";
import Select from "react-select";
import amazonIcon from "../../assets/icons/amazon.png"
import { countries } from "../../utils/countries"
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchAmazonSellerCentralAuthUrl,
  loadAmazonSellerCentralAuthStatus,
  revokeAmazonSellerCentralAccess
} from "../../store/actions/auth/amazonSellerCentralAuthActions";
import LoadingMessage from "../../components/helpers/LoadingMessage";
import { ACCESS_DENIED_PAGE } from "../../Routes/urls";

const AmazonSellerCentral = ({ history }) => {
  const auth = useSelector(state => state.auth)
  const amazonSellerCentral = useSelector((state) => state.amazonSellerCentral);

  const [country, setCountry] = useState('');
  const dispatch = useDispatch();

  setPageTitle("Amazon Seller Central")

  useEffect(() => {
    if (!auth.loading) {
      const grantedDataSources = auth.user.data_sources
      const isDataSourcePresent = grantedDataSources.filter(dataSource => dataSource.name === 'Amazon Seller Central').length

      if (!isDataSourcePresent) {
        history.push(ACCESS_DENIED_PAGE);
      }
    }
  }, [auth, history])

  const handleClick = () => {
    dispatch(fetchAmazonSellerCentralAuthUrl({
      "country_name": `${country}`
    }))
  }

  useEffect(() => {
    dispatch(loadAmazonSellerCentralAuthStatus());
    //eslint-disable-next-line
  }, []);


  return (
    <LayoutContainer>
      <div className={classes.connectForm}>
        <div className={classes.connectForm_box}>
          <ConnectFormHeader
            icon={amazonIcon}
            title="Amazon Seller Central"
            connected={amazonSellerCentral.connected}
            revokeDisabled={amazonSellerCentral.loading}
            connectDisabled={true}
            onRevokeClick={() => dispatch(revokeAmazonSellerCentralAccess())}
          />
          {amazonSellerCentral.loading ? (
            <div className={classes.connectForm_loadingState}>
              <LoadingMessage />
            </div>
          ) : (
            <>
              {amazonSellerCentral.connected ? (
                <>
                  You've connected an account<br />
                  Connected Selling Partner ID: {amazonSellerCentral.selling_partner_id}
                </>
              ) : (
                <>
                  <div className={classes.connectForm_form}>
                    <div style={{ width: "100%" }}>
                      <h3>Please Select Country</h3>
                      <Select
                        placeholder={"Select Country"}
                        options={countries}
                        onChange={(e) => setCountry(e.label)}
                      />
                    </div>
                  </div>

                  <div
                    className={classes.connectForm_buttons}
                    style={{ marginTop: `50px` }}
                  >
                    <button
                      disabled={amazonSellerCentral.loading || !country}
                      type="submit"
                      onClick={handleClick}
                      className={classes.connectForm_intentwiseButton}
                    >
                      Next
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </LayoutContainer >
  );
};

export default AmazonSellerCentral;
