import React, { useState, useEffect } from "react";
import dateImg from "../../assets/images/registerPage.svg";
import { useSelector, useDispatch } from "react-redux";
import { register } from "../../store/actions/auth/authActions";
import { Link } from "react-router-dom";
import classes from "./authStyles.module.scss";
import AuthLayoutContainer from "../../components/layouts/AuthLayoutContainer";
import { setPageTitle, getUserLocationDetails } from "../../utils";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { LOGIN_PAGE } from "../../Routes/urls";
import ErrorMessage from "../../components/FormElements/ErrorMessage";

const RegisterPage = ({ history }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const isNightMode = useSelector((state) => state?.theme?.nightMode);

  setPageTitle("Register");

  // states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_2, setPassword2] = useState("");
  const [username, setUsername] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [countryCode, setCountryCode] = useState("US");

  // handlers
  const submitHandler = (event) => {
    event.preventDefault();
    setDisable(true);

    const registerData = {
      email,
      password,
      password_2,
      username,
      first_name,
      last_name,
      phone,
    };
    dispatch(register(registerData, history));
  };

  useEffect(() => {
    if (!!auth.registerErrors.non_field_errors) {
      setMessage(auth.registerErrors.non_field_errors[0]);
    }
    if (!auth.loading) {
      setDisable(false);
    }
  }, [auth]);

  useEffect(() => {
    if (phone) {
      if (isValidPhoneNumber(phone)) {
        setErrors({
          ...errors,
          phone: "",
        });
      } else {
        setErrors({
          ...errors,
          phone: "Invalid phone number",
        });
      }
    }

    // eslint-disable-next-line
  }, [phone]);

  useEffect(() => {
    getUserLocationDetails()
      .then((data) => {
        setCountryCode(data?.country?.iso_code || "US");
      })
      .catch((error) => setCountryCode("US"));
  }, []);

  return (
    <AuthLayoutContainer>
      <div
        className={`${classes.authContainer} ${
          isNightMode && classes.authContainer_nightMode
        }`}
      >
        <div className={classes.authContainer_leftSide}>
          <img
            className={classes.authContainer_image}
            src={dateImg}
            alt="Date Range"
          />
        </div>
        <div className={classes.authContainer_rightSide}>
          <h2 className={classes.authContainer_rightSide__header}>
            Create a new account
          </h2>

          <form onSubmit={submitHandler}>
            <div className={classes.inputContainer}>
              <label className={classes.inputContainer_label}>
                Email
              </label>
              <input
                type="email"
                className={classes.inputContainer_input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {auth.registerErrors.email && (
                <ErrorMessage message={auth.registerErrors.email} />
              )}
            </div>
            <div className={classes.inputContainer}>
              <label className={classes.inputContainer_label}>
                Username
              </label>
              <input
                type="text"
                className={classes.inputContainer_input}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              {auth.registerErrors.username && (
                <ErrorMessage message={auth.registerErrors.username} />
              )}
            </div>
            <div className={classes.inputContainer}>
              <label className={classes.inputContainer_label}>
                Password
              </label>
              <input
                autoComplete="new-password"
                type="password"
                className={classes.inputContainer_input}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {auth.registerErrors.password && (
                <ErrorMessage message={auth.registerErrors.password} />
              )}
            </div>
            <div className={classes.inputContainer}>
              <label className={classes.inputContainer_label}>
                Confirm password
              </label>
              <input
                type="password"
                className={classes.inputContainer_input}
                value={password_2}
                onChange={(e) => setPassword2(e.target.value)}
              />
              {auth.registerErrors.password_2 && (
                <ErrorMessage message={auth.registerErrors.password_2} />
              )}
            </div>

            <div className={classes.inputContainer}>
              <label className={classes.inputContainer_label}>
                First name
              </label>
              <input
                type="text"
                className={classes.inputContainer_input}
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {auth.registerErrors.first_name && (
                <ErrorMessage message={auth.registerErrors.first_name} />
              )}
            </div>

            <div className={classes.inputContainer}>
              <label className={classes.inputContainer_label}>
                Last name
              </label>
              <input
                type="text"
                className={classes.inputContainer_input}
                value={last_name}
                onChange={(e) => setLastName(e.target.value)}
              />
              {auth.registerErrors.last_name && (
                <ErrorMessage message={auth.registerErrors.last_name} />
              )}
            </div>

            <div className={classes.inputContainer}>
              <label className={classes.inputContainer_label}>
                Country
              </label>
              <input
                type="text"
                className={classes.inputContainer_input}
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
              {auth.registerErrors.country && (
                <ErrorMessage message={auth.registerErrors.country} />
              )}
            </div>

            <div className={classes.inputContainer}>
              <label className={classes.inputContainer_label}>
                Phone number
              </label>
              <PhoneInput
                className={classes.inputContainer_input}
                value={phone}
                onChange={setPhone}
                defaultCountry={countryCode}
                countryCallingCodeEditable={true}
                international
              />
              {auth.registerErrors.phone && (
                <ErrorMessage message={auth.registerErrors.phone} />
              )}
              {errors.phone && (
                <ErrorMessage message={errors.phone} />
              )}
            </div>
            <button
              className={classes.authContainer_submitBtn}
              disabled={
                disable ||
                !Object.values(errors).every(
                  (x) => x === null || x === "" || x === undefined
                )
              }
              type="submit"
            >
              {auth.loading ? "Please wait..." : "Create account"}
            </button>
          </form>

          {message && <p style={{ color: "red" }}>{message}</p>}

          <div className={classes.authContainer_linkText}>
            Already have an account?{" "}
            <Link
              to={{
                pathname: LOGIN_PAGE,
                search: history.location.search,
              }}
              className={classes.authContainer_link}
            >
              Login Here
            </Link>
          </div>
        </div>
      </div>
    </AuthLayoutContainer>
  );
};

export default RegisterPage;
