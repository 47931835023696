import axios from "axios";
export function getHeaders(additional) {
  const userToken = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  const timezoneOffset = new Date().getTimezoneOffset();

  let headers = {
    ...additional,
    "X-Api-Timezone-Offset": timezoneOffset,
  };

  if (userToken) {
    headers["Authorization"] = `Token ${userToken}`;
  }

  return headers;
}

export function createUUID() {
  // used to generate a random UUID (for key props)
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : r && 0x3 | 0x8;
    return v.toString(16);
  });
}

export const setPageTitle = (title) => {
  const siteName = process.env.REACT_APP_SITE_TITLE;
  if (title) {
    document.title = title + " | " + siteName;
  } else {
    document.title = siteName;
  }
  return true;
};

export const getUserLocationDetails = async () => {
  const API_KEY = "d011aa6de6644cd093dbd2111eb59286";
  const url = `https://api.geoapify.com/v1/ipinfo?apiKey=${API_KEY}`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.log("Error occured while getting user location info");
    return {};
  }
};


export const createGradient = (svg, i) => {
  const linearGradientDef = svg.append("defs")
    .append("linearGradient")
    .attr("id", `gradient${i + 1}`)
    .attr("x1", "0%")
    .attr("x2", "0%")
    .attr("y1", "100%")
    .attr("y2", "0%")

  linearGradientDef.append("stop")
    .attr("offset", "0%")
    .style("stop-opacity", "0")


  linearGradientDef.append("stop")
    .attr("offset", "50%")
    .style("stop-opacity", "0.2")

  linearGradientDef.append("stop")
    .attr("offset", "70%")
    .style("stop-opacity", "0.3")

  linearGradientDef.append("stop")
    .attr("offset", "80%")
    .style("stop-opacity", "0.4")

  linearGradientDef.append("stop")
    .attr("offset", "100%")
    .style("stop-opacity", "0.5")

  return { linearGradientDef }
}
