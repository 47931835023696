// auth action types
// user
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const USER_REGISTERED = "USER_REGISTERED";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_LOGOUT_ERROR = "USER_LOGOUT_ERROR";
export const AUTH_LOADING = "AUTH_LOADING"; // for all kind of auth stuffs (login, register, reset password bla bla bla)
export const USER_INFO_LOADED = "USER_INFO_LOADED";
export const USER_INFO_LOAD_ERROR = "USER_INFO_LOAD_ERROR";
export const TOGGLE_FORGOT_PASSWORD = "TOGGLE_FORGOT_PASSWORD";
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_STATUS = "RESET_PASSWORD_STATUS";
export const CHANGE_PASSWORD_ERRORS = "CHANGE_PASSWORD_ERRORS";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";

export const PROFILE_UPDATE_ERRORS = "PROFILE_UPDATE_ERRORS";
export const PROFILE_UPDATED = "PROFILE_UPDATED";

// intentwise
export const INTENTWISE_AUTH_STATUS_LOADING = "INTENTWISE_AUTH_STATUS_LOADING";
export const INTENTWISE_AUTH_STATUS_LOADED = "INTENTWISE_AUTH_STATUS_LOADED";
export const INTENTWISE_AUTH_STATUS_LOAD_ERROR = "INTENTWISE_AUTH_STATUS_LOAD_ERROR";
export const INTENTWISE_CREDENTIALS_LOADING = "INTENTWISE_CREDENTIALS_LOADING";
export const INTENTWISE_CREDENTIALS_LOADED = "INTENTWISE_CREDENTIALS_LOADED";
export const INTENTWISE_CREDENTIALS_LOAD_ERROR = "INTENTWISE_CREDENTIALS_LOAD_ERROR";
export const INTENTWISE_SAVE_CREDENTIALS_LOADING = "INTENTWISE_SAVE_CREDENTIALS_LOADING";
export const INTENTWISE_SAVE_CREDENTIALS_LOADED = "INTENTWISE_SAVE_CREDENTIALS_LOADED";
export const INTENTWISE_SAVE_CREDENTIALS_SUCCESS = "INTENTWISE_SAVE_CREDENTIALS_SUCCESS";
export const INTENTWISE_SAVE_CREDENTIALS_LOAD_ERROR = "INTENTWISE_SAVE_CREDENTIALS_LOAD_ERROR";
export const INTENTWISE_AUTH_ERROR = "INTENTWISE_AUTH_ERROR";
export const INTENTWISE_AUTH_SUCCESS = "INTENTWISE_AUTH_SUCCESS";
export const INTENTWISE_ACCOUNTS_LOADED = "INTENTWISE_ACCOUNTS_LOADED";


// amazon seller central
export const AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOADING = "AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOADING";
export const AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOADED = "AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOADED";
export const AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOAD_ERROR = "AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOAD_ERROR";
export const AMAZON_SELLER_CENTRAL_CREDENTIALS_LOADING = "AMAZON_SELLER_CENTRAL_CREDENTIALS_LOADING";
export const AMAZON_SELLER_CENTRAL_CREDENTIALS_LOADED = "AMAZON_SELLER_CENTRAL_CREDENTIALS_LOADED";
export const AMAZON_SELLER_CENTRAL_CREDENTIALS_LOAD_ERROR = "AMAZON_SELLER_CENTRAL_CREDENTIALS_LOAD_ERROR";
export const AMAZON_SELLER_CENTRAL_SAVE_CREDENTIALS_LOADING = "AMAZON_SELLER_CENTRAL_SAVE_CREDENTIALS_LOADING";
export const AMAZON_SELLER_CENTRAL_SAVE_CREDENTIALS_LOADED = "AMAZON_SELLER_CENTRAL_SAVE_CREDENTIALS_LOADED";
export const AMAZON_SELLER_CENTRAL_SAVE_CREDENTIALS_SUCCESS = "AMAZON_SELLER_CENTRAL_SAVE_CREDENTIALS_SUCCESS";
export const AMAZON_SELLER_CENTRAL_SAVE_CREDENTIALS_LOAD_ERROR = "AMAZON_SELLER_CENTRAL_SAVE_CREDENTIALS_LOAD_ERROR";
export const AMAZON_SELLER_CENTRAL_AUTH_ERROR = "AMAZON_SELLER_CENTRAL_AUTH_ERROR";
export const AMAZON_SELLER_CENTRAL_AUTH_SUCCESS = "AMAZON_SELLER_CENTRAL_AUTH_SUCCESS";
export const AMAZON_SELLER_CENTRAL_ACCOUNTS_LOADED = "AMAZON_SELLER_CENTRAL_ACCOUNTS_LOADED";


// template
export const SET_ACTIVE_TEMPLATE = "SET_ACTIVE_TEMPLATE";
export const SET_ACTIVE_BRAND = "SET_ACTIVE_BRAND";
export const TOGGLE_DARK_PICTURES = "TOGGLE_DARK_PICTURES";
export const TEMPLATE_DATA_LOADING = "TEMPLATE_DATA_LOADING";
export const TEMPLATE_DATA_LOADED = "TEMPLATE_DATA_LOADED";
export const TEMPLATE_DATA_LOAD_ERROR = "TEMPLATE_DATA_LOAD_ERROR";

// INTENTWISE
// nuggets data
export const nuggetActionTypes = {};
for (let i = 1; i < 6; i++) {
  nuggetActionTypes[`NUGGET_${i}_DATA_LOADING`] = `NUGGET_${i}_DATA_LOADING`;
  nuggetActionTypes[`NUGGET_${i}_DATA_LOADED`] = `NUGGET_${i}_DATA_LOADED`;
  nuggetActionTypes[
    `NUGGET_${i}_DATA_LOAD_ERROR`
  ] = `NUGGET${i}_DATA_LOAD_ERROR`;
}

// charts data
export const chartActionTypes = {};
for (let i = 6; i < 20; i++) {
  chartActionTypes[`CHART_${i}_DATA_LOADING`] = `CHART_${i}_DATA_LOADING`;
  chartActionTypes[`CHART_${i}_DATA_LOADED`] = `CHART_${i}_DATA_LOADED`;
  chartActionTypes[`CHART_${i}_DATA_LOAD_ERROR`] = `CHART_${i}_DATA_LOAD_ERROR`;
}

// AMAZON
// nuggets data
export const amazonNuggetActionTypes = {};
for (let i = 1; i < 5; i++) {
  amazonNuggetActionTypes[`AMAZON_NUGGET_${i}_DATA_LOADING`] = `AMAZON_NUGGET_${i}_DATA_LOADING`;
  amazonNuggetActionTypes[`AMAZON_NUGGET_${i}_DATA_LOADED`] = `AMAZON_NUGGET_${i}_DATA_LOADED`;
  amazonNuggetActionTypes[
    `AMAZON_NUGGET_${i}_DATA_LOAD_ERROR`
  ] = `AMAZON_NUGGET${i}_DATA_LOAD_ERROR`;
}

// charts data
export const amazonChartActionTypes = {};
for (let i = 5; i < 11; i++) {
  amazonChartActionTypes[`AMAZON_CHART_${i}_DATA_LOADING`] = `AMAZON_CHART_${i}_DATA_LOADING`;
  amazonChartActionTypes[`AMAZON_CHART_${i}_DATA_LOADED`] = `AMAZON_CHART_${i}_DATA_LOADED`;
  amazonChartActionTypes[`AMAZON_CHART_${i}_DATA_LOAD_ERROR`] = `AMAZON_CHART_${i}_DATA_LOAD_ERROR`;
}
export const AMAZON_RESET_CHART_DATA = "AMAZON_RESET_CHART_DATA";


export const RESET_CHART_DATA = "RESET_CHART_DATA";

// date range
export const SET_DATE_RANGE = "SET_DATE_RANGE";


// Theme
export const NIGHTMODE_TOGGLE = "NIGHTMODE_TOGGLE";
