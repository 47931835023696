import * as Types from "../actions/actionTypes";

const initialState = {
  loading: false,
  connected: false,
  account_name: "",
  selling_partner_id: "",
  is_active: false,
  country_name: "",
  currency: ""
};

function amazonSellerCentralReducer(state = initialState, action) {
  switch (action.type) {
    case Types.AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOADED: {
      return {
        ...state,
        connected: action.payload.connected,
        account_name: action.payload.account_name,  
        selling_partner_id: action.payload.selling_partner_id,
        is_active: action.payload.is_active,
        country_name: action.payload.country_name,
        currency: action.payload.currency,
        errors: {},
      };
    }
    case Types.AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOADING: {
      return {
        ...state,
        loading: action.payload,
        errors: {},
      };
    }
    case Types.AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOAD_ERROR: {
      return {
        ...state,
        errors: action.payload,
      };
    }
    case Types.AMAZON_SELLER_CENTRAL_CREDENTIALS_LOADING: {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          loading: action.payload
        }
      };
    }
    case Types.AMAZON_SELLER_CENTRAL_CREDENTIALS_LOADED: {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          data: action.payload,
        }
      };
    }
    case Types.AMAZON_SELLER_CENTRAL_SAVE_CREDENTIALS_LOADING: {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          loading: action.payload
        }
      };
    }
    case Types.AMAZON_SELLER_CENTRAL_SAVE_CREDENTIALS_LOADED: {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          data: action.payload,
          errors: {}
        }
      };
    }
    case Types.AMAZON_SELLER_CENTRAL_SAVE_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          success: action.payload
        }
      };
    }
    case Types.AMAZON_SELLER_CENTRAL_SAVE_CREDENTIALS_LOAD_ERROR: {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          success: false,
          errors: action.payload
        }
      };
    }
    case Types.AMAZON_SELLER_CENTRAL_ACCOUNTS_LOADED: {
      return {
        ...state,
        accounts_list: action.payload,
      };
    }

    default:
      return state;
  }
}

export default amazonSellerCentralReducer;
