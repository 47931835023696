import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  loadIntentwiseCredential,
  revokeIntentwiseAccess,
  updateIntentwiseCredential,
  loadIntentwiseAccounts,
  loadIntentwiseAuthStatus,
} from "../../store/actions/auth/intentwiseAuthActions";
import LayoutContainer from "../../components/layouts/LayoutContainer";
import classes from "./styles.module.scss";
import intentwiseIcon from "../../assets/icons/intentwise.png";
import LoadingMessage from "../../components/helpers/LoadingMessage";
import { setPageTitle } from "../../utils";
import ConnectFormHeader from "./ConnectFormHeader";
import Select from "react-select";

const Intentwise = () => {
  const intentwise = useSelector((state) => state.intentwise);

  const [host, setHost] = useState("analytics.intentwise.com");
  const [port, setPort] = useState("5439");
  const [db_name, setDBName] = useState("data_platform");
  const [db_user, setDBUser] = useState("");
  const [db_password, setDBPassword] = useState("");
  const [account_id, setAccountId] = useState("");

  const dispatch = useDispatch();

  setPageTitle("Intentwise");

  useEffect(() => {
    if (intentwise.credentials.data?.db_host) {
      setHost(intentwise.credentials.data?.db_host || "");
    }
    if (intentwise.credentials.data?.db_port) {
      setPort(intentwise.credentials.data?.db_port || "");
    }
    if (intentwise.credentials.data?.db_name) {
      setDBName(intentwise.credentials.data?.db_name || "");
    }
    setDBUser(intentwise.credentials.data?.db_username || "");
    setDBPassword(intentwise.credentials.data?.db_password || "");
    setAccountId(intentwise.credentials.data?.account_id || "");
  }, [intentwise.credentials.data]);

  useEffect(() => {
    dispatch(loadIntentwiseAuthStatus());
    dispatch(loadIntentwiseCredential());
    //eslint-disable-next-line
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(
      updateIntentwiseCredential({
        db_host: host,
        db_port: port,
        db_name: db_name,
        db_username: db_user,
        db_password: db_password,
        account_id: account_id,
      })
    );
  };

  const getAccountList = (event) => {
    event.preventDefault();
    dispatch(
      loadIntentwiseAccounts({
        db_host: host,
        db_port: port,
        db_name: db_name,
        db_username: db_user,
        db_password: db_password,
      })
    );
  };

  return (
    <LayoutContainer>
      <div className={classes.connectForm}>
        <div className={classes.connectForm_box}>
          <ConnectFormHeader
            icon={intentwiseIcon}
            title="Intentwise"
            connected={intentwise.connected}
            revokeDisabled={intentwise.loading}
            connectDisabled={true}
            onRevokeClick={() => dispatch(revokeIntentwiseAccess())}
          />

          {intentwise.loading ? (
            <div className={classes.connectForm_loadingState}>
              <LoadingMessage />
            </div>
          ) : (
            <>
              {intentwise.connected ? (
                <>
                  You've connected an account. <br />
                  Account Name: {intentwise.account_name}
                </>
              ) : (
                <>
                  {!!intentwise.accounts_list ? (
                    <div className={classes.connectForm_form}>
                      <div style={{ width: "100%" }}>
                        <Select
                          defaultValue={account_id}
                          placeholder={"Please Select an Account.."}
                          options={intentwise.accounts_list?.map((acc) => ({
                            label: acc.name,
                            value: acc.id,
                          }))}
                          onChange={(value) => {
                            setAccountId(value.value);
                          }}
                        />
                        {intentwise.credentials.errors?.account_id && (
                          <div className={classes.connectForm_error}>
                            {intentwise.credentials.errors?.account_id}
                          </div>
                        )}
                      </div>

                      {Object.keys(intentwise.credentials.errors).length > 0 && (
                        <div className={classes.connectForm_form__errorMessage}>
                          Couldn't save changes, please check your credentials
                        </div>
                      )}

                      <div
                        className={classes.connectForm_buttons}
                        style={{ marginTop: `50px` }}
                      >
                        <button
                          disabled={intentwise.credentials.loading}
                          type="submit"
                          onClick={submitHandler}
                          className={classes.connectForm_intentwiseButton}
                        >
                          {intentwise.credentials.loading
                            ? "Please wait..."
                            : "Save changes"}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <form className={classes.connectForm_form}>
                      <div className={classes.inputsWrapper}>
                        <div className={classes.connectForm_form__inputContainer}>
                          <label htmlFor="host">Host Name</label>
                          <input
                            onChange={(e) => setHost(e.target.value)}
                            placeholder="Enter db host. Eg: analytics.intentwise.com"
                            value={host}
                          />
                          {intentwise.credentials.errors?.db_host && (
                            <div className={classes.connectForm_error}>
                              {intentwise.credentials.errors?.db_host}
                            </div>
                          )}
                        </div>
                        <div className={classes.connectForm_form__inputContainer}>
                          <label htmlFor="port">Port</label>
                          <input
                            onChange={(e) => setPort(e.target.value)}
                            placeholder="Enter db port. Eg: 5439"
                            value={port}
                          />
                          {intentwise.credentials.errors?.db_port && (
                            <div className={classes.connectForm_error}>
                              {intentwise.credentials.errors?.db_port}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={classes.inputsWrapper}>
                        <div className={classes.connectForm_form__inputContainer}>
                          <label htmlFor="db_name">Database</label>
                          <input
                            onChange={(e) => setDBName(e.target.value)}
                            placeholder="Enter db name. Eg: data_platform"
                            value={db_name}
                          />
                          {intentwise.credentials.errors?.db_name && (
                            <div className={classes.connectForm_error}>
                              {intentwise.credentials.errors?.db_name}
                            </div>
                          )}
                        </div>
                        <div className={classes.connectForm_form__inputContainer}>
                          <label htmlFor="db_user">Username</label>
                          <input
                            onChange={(e) => setDBUser(e.target.value)}
                            placeholder="Enter db username"
                            value={db_user}
                          />
                        {intentwise.credentials.errors?.db_username && (
                          <div className={classes.connectForm_error}>
                            {intentwise.credentials.errors?.db_username}
                          </div>
                        )}
                        </div>
                      </div>

                      <div className={classes.connectForm_form__inputContainer}>
                        <label htmlFor="db_password">Password</label>
                        <input
                          onChange={(e) => setDBPassword(e.target.value)}
                          placeholder="Enter db password"
                          value={db_password}
                        />
                        {intentwise.credentials.errors?.db_password && (
                          <div className={classes.connectForm_error}>
                            {intentwise.credentials.errors?.db_password}
                          </div>
                        )}
                      </div>

                      {Object.keys(intentwise.credentials.errors).length > 0 && (
                        <div className={classes.connectForm_form__errorMessage}>
                          Couldn't save changes, please check your credentials
                        </div>
                      )}
                      {intentwise.credentials.success && (
                        <div className={classes.connectForm_form__successMessage}>
                          Changes saved successfully!
                        </div>
                      )}
                      <div className={classes.connectForm_buttons}>
                        <button
                          disabled={intentwise.credentials.loading}
                          type="submit"
                          onClick={getAccountList}
                          className={classes.connectForm_intentwiseButton}
                        >
                          {intentwise.credentials.loading
                            ? "Please wait..."
                            : "Select Account"}
                        </button>
                      </div>
                    </form>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </LayoutContainer>
  );
};

export default Intentwise;
