import React, { useState } from "react";

import LayoutContainer from "../../components/layouts/LayoutContainer";
import image from "../../assets/images/settings_page_image.svg";
import darkImage from "../../assets/images/settings_page_image_dark.svg";
import classes from "./styles.module.scss";
import DataSourceConnect from "./DataSourceConnect";
import ChangePassword from "./ChangePassword";
import UpdateProfile from "./UpdateProfile";

import { useSelector } from "react-redux";
import { setPageTitle } from "../../utils";

const SettingsPage = ({ history }) => {
  const [component, setComponent] = useState("dataSource");
  const isNightMode = useSelector((state) => state?.theme?.nightMode);

  setPageTitle("Settings");

  return (
    <LayoutContainer>
      <div
        className={`${classes.setting} ${isNightMode && classes.setting_nightMode
          }`}
      >
        <div className={classes.setting_left}>
          <img
            src={isNightMode ? darkImage : image}
            className={classes.setting_image}
            alt="Date range"
          />
        </div>

        <div className={classes.setting_right}>
          <div className={classes.listWrap}>
            <span
              className={`${classes.listWrap_item} ${component === "dataSource" && classes.listWrap_item__active
                }`}
              onClick={() => setComponent("dataSource")}
            >
              Data Source
            </span>

            <span
              className={`${classes.listWrap_item} ${component === "profile" && classes.listWrap_item__active
                }`}
              onClick={() => setComponent("profile")}
            >
              Profile
            </span>

            <span
              className={`${classes.listWrap_item} ${component === "changePassword" && classes.listWrap_item__active
                }`}
              onClick={() => setComponent("changePassword")}
            >
              Password
            </span>
          </div>
          {component === "dataSource" && <DataSourceConnect />}
          {component === "profile" && <UpdateProfile />}
          {component === "changePassword" && <ChangePassword />}
        </div>
      </div>
    </LayoutContainer>
  );
};

export default SettingsPage;
