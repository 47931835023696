import React, { useEffect } from "react";
import intentwiseIcon from "../../assets/icons/intentwise.png";
import SettingsServiceItem from "../../components/settings/SettingsServiceItem";
import { Link } from 'react-router-dom'
import classes from "./styles.module.scss";
import { AMAZON_SELLER_CENTRAL_ACCESS_PAGE, INTENTWISE_ACCESS_PAGE } from "../../Routes/urls";
import { useDispatch, useSelector } from "react-redux";
import { loadIntentwiseAuthStatus } from "../../store/actions/auth/intentwiseAuthActions";
import amazonIcon from "../../assets/icons/amazon.png"


const DataSourceConnect = () => {
  const dispatch = useDispatch();

  const intentwise = useSelector((state) => state.intentwise);
  const amazonSellerCentral = useSelector((state) => state.amazonSellerCentral);
  const auth = useSelector(state => state.auth)

  useEffect(() => {
    if (auth.user?.data_sources?.map(d => d.name)?.includes("Intentwise")) {
      dispatch(loadIntentwiseAuthStatus());
    }

    //eslint-disable-next-line
  }, [auth.user?.data_sources]);

  return (
    <>
      <div className={classes.setting_connectForm}>
        <h2 className={classes.setting_connectForm__header}>
          Connect with Data Sources
        </h2>
        {auth.user?.data_sources?.length ? (
          <>
            {auth.user?.data_sources?.map(d => d.name)?.includes("Intentwise") && (
              <SettingsServiceItem
                settingsLink={INTENTWISE_ACCESS_PAGE}
                connectLink={INTENTWISE_ACCESS_PAGE}
                title="Intentwise:"
                icon={intentwiseIcon}
                connected={intentwise.connected}
                accountSelected={true}
                loading={false}
              />
            )}
            {auth.user?.data_sources?.map(d => d.name)?.includes("Amazon Seller Central") && (
              <SettingsServiceItem
                settingsLink={AMAZON_SELLER_CENTRAL_ACCESS_PAGE}
                connectLink={AMAZON_SELLER_CENTRAL_ACCESS_PAGE}
                title="Amazon Seller Central:"
                icon={amazonIcon}
                connected={amazonSellerCentral.connected}
                accountSelected={true}
                loading={false}
              />
            )}
          </>
        ) : (
          <h4 style={{ color: "rgb(230, 30, 20)" }}>
            You don't have access to any data sources!
          </h4>
        )}
      </div>
      <div className={classes.footerSettings}>
        <div className={classes.rightAligner}>
          <Link to="/">
            <button
              className={classes.buttonDash}
            >
              View My Dash
            </button>
          </Link>
        </div>
      </div>
    </>
  )

}


export default DataSourceConnect