import React from "react";
import classes from "./styles.module.scss";
import Footer from "./Footer";
import logo from "../../assets/images/dash_logo.png";
import nightIcon from "../../assets/images/sidebar/nightAuth.svg";
import lightIcon from "../../assets/images/sidebar/lightIcon.svg";
import { NIGHTMODE_TOGGLE } from "../../store/actions/actionTypes";
import { useSelector, useDispatch } from "react-redux";

const AuthLayoutContainer = ({ children }) => {
  const isNightMode = useSelector((state) => state?.theme?.nightMode);
  const dispatch = useDispatch();
  return (
    <>
      <div
        className={`${classes.authBody} ${
          isNightMode && classes.authBody_nightMode
        }`}
      >
        <div className={classes.whiteBG}>
          <div className={classes.headerContainer}>
            <a href="https://mydash.ai">
              <img className={classes.siteLogo} src={logo} alt="" />
            </a>
            <div className={classes.headerContainer_links}>
              <a href="https://mydash.ai" target="_blank" rel="noreferrer">
                MyDash <span>-</span> Website
              </a>
              <a href="https://mydash.report" target="_blank" rel="noreferrer">
                MyDash <span>-</span> App
              </a>
              <div
                className={classes.headerContainer_themeToggle}
                onClick={() => {
                  localStorage.setItem(
                    "theme",
                    localStorage.getItem("theme") === "dark" ? "light" : "dark"
                  );
                  dispatch({
                    type: NIGHTMODE_TOGGLE,
                    payload:
                      localStorage.getItem("theme") === "dark" ? true : false,
                  });
                }}
              >
                <img
                  src={isNightMode ? lightIcon : nightIcon}
                  alt="nightmode"
                />
              </div>
            </div>
          </div>
        </div>
        {children}
        <Footer />
      </div>
    </>
  );
};

export default AuthLayoutContainer;
