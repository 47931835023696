import { combineReducers } from "redux";
import authReducer from "./authReducer";
import intentwiseReducer from "./intentwiseReducer";
import amazonSellerCentralReducer from "./amazonSellerCentralReducer";
import nuggetReducer from "./chartReducers/nuggetReducer";
import chartReducer from "./chartReducers/chartReducer";
import templateReducer from "./templateReducer";
import dateRangeReducer from "./dateRangeReducer";
import themeReducer from "./themeReducer";
import amazonChartReducer from "./chartReducers/amazonChartReducer";
import amazonNuggetReducer from "./chartReducers/amazonNuggetReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  intentwise: intentwiseReducer,
  amazonSellerCentral: amazonSellerCentralReducer,
  nuggetData: nuggetReducer,
  chartData: chartReducer,
  amazonNuggetData: amazonNuggetReducer,
  amazonChartData: amazonChartReducer,
  template: templateReducer,
  dateRange: dateRangeReducer,
  theme: themeReducer,
});

export default rootReducer;
